<template>
  <div>
    <v-col cols="12" class="font-weight-medium body-1 pb-0">
      <span v-if="question.number">{{ question.number }}. </span>{{ question.question }}
    </v-col>
    <v-col cols="12" class="pb-0">
      <v-radio-group v-model="answer" class="mt-0" :error="error">
        <v-radio
          class="body-2"
          v-for="q in question.answers"
          :key="q.value"
          :label="q.label"
          :value="q.value"
          :click="updateValue()"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "QuestionRadio",
  props: ["question", "error"],
  data() {
    return {
      answer: ''
    }
  },
  methods: {
    updateValue() {
      this.$emit('input', this.answer);
    }
  }
}
</script>
