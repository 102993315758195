<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>Osobné údaje</h2>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          filled
          dense
          label="Celé Meno"
          v-model="form.fullName"
          :error="$v.form.fullName.$anyError"
          required
          @input="$v.form.fullName.$touch()"
          @blur="$v.form.fullName.$touch()"/>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          filled
          dense
          label="E-mail"
          v-model="form.email"
          :error="$v.form.email.$anyError"
          required
          @input="$v.form.email.$touch()"
          @blur="$v.form.email.$touch()"/>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          filled
          dense
          label="Telefón"
          v-model="form.phone"
          :error="$v.form.phone.$anyError"
          placeholder="+### ### ### ###"
          v-mask="'+### ### ### ###'"
          required
          @input="$v.form.phone.$touch()"
          @blur="$v.form.phone.$touch()"/>
      </v-col>
      <v-col cols="12" sm="3">
        <v-menu
          v-model="birthdayMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              filled
              dense
              v-model="birthdayFormatted"
              label="Dátum narodenia"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :error="$v.form.birthdate.$anyError"
              @input="$v.form.birthdate.$touch()"
              @blur="$v.form.birthdate.$touch()"
            ></v-text-field>
          </template>
          <v-date-picker
            :first-day-of-week="1"
            locale="sk-SK"
            v-model="form.birthdate"
            no-title
            @input="birthdayMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          filled
          dense
          label="Rodné číslo"
          v-model="form.birthNumber"
          :error="$v.form.birthNumber.$anyError"
          required
          @input="$v.form.birthNumber.$touch()"
          @blur="$v.form.birthNumber.$touch()"/>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          filled
          dense
          label="Číslo občianskeho preukazu"
          v-model="form.personalCard"
          :error="$v.form.personalCard.$anyError"
          required
          @input="$v.form.personalCard.$touch()"
          @blur="$v.form.personalCard.$touch()"/>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          filled
          dense
          label="IBAN"
          v-model="form.iban"
          :error="$v.form.iban.$anyError"
          required
          @input="$v.form.iban.$touch()"
          @blur="$v.form.iban.$touch()"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Bydlisko</h2>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          filled
          dense
          label="Ulica"
          v-model="form.street"
          :error="$v.form.street.$anyError"
          required
          @input="$v.form.street.$touch()"
          @blur="$v.form.street.$touch()"/>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          filled
          dense
          label="PSČ"
          v-model="form.zip"
          :error="$v.form.zip.$anyError"
          required
          @input="$v.form.zip.$touch()"
          @blur="$v.form.zip.$touch()"/>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          filled
          dense
          label="Mesto"
          v-model="form.city"
          :error="$v.form.city.$anyError"
          required
          @input="$v.form.city.$touch()"
          @blur="$v.form.city.$touch()"/>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          filled
          dense
          label="Krajina"
          v-model="form.country"
          required
          :error="$v.form.country.$anyError"
          @input="$v.form.country.$touch()"
          @blur="$v.form.country.$touch()"
          :items="countries"
          item-text="text"
          item-value="text"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Dotazník</h2>
        <v-divider></v-divider>
      </v-col>

      <question-radio :question="question1" v-model="form.question1" :error="$v.form.question1.$anyError" />
      <v-col cols="12" sm="4" class="pt-0 mt-0" v-if="form.question1 === 'e'">
        <v-text-field
          filled
          dense
          label="Popis"
          :error="$v.form.question1Other.$anyError"
          @input="$v.form.question1Other.$touch()"
          @blur="$v.form.question1Other.$touch()"
          v-model="form.question1Other"/>
      </v-col>

      <question-radio :question="question2" v-model="form.question2" :error="$v.form.question2.$anyError" />

      <question-radio :question="question3" v-model="form.question3" :error="$v.form.question3.$anyError" />

      <question-radio :question="question4" v-model="form.question4" :error="$v.form.question4.$anyError" />

      <question-radio :question="question5" v-model="form.question5" :error="$v.form.question5.$anyError" />

      <question-radio :question="question6" v-model="form.question6" :error="$v.form.question6.$anyError" />

      <question-radio :question="question7" v-model="form.question7" :error="$v.form.question7.$anyError" />

    </v-row>

    <v-row>
      <v-col cols="12" class="font-weight-medium body-1 pb-0">
        8. Uveďte prosím Vaše skúsenosti s investovaním do finančných nástrojov:
      </v-col>
      <v-col cols="12" class="pb-0">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left"></th>
              <th class="text-center body-2 font-weight-bold">
                Dlhopisy, dlhopisové podielové fondy
              </th>
              <th class="text-center body-2 font-weight-bold">
                Zmiešané, akciové a realitné podielové fondy
              </th>
              <th class="text-center body-2 font-weight-bold">
                Akcie
              </th>
              <th class="text-center body-2 font-weight-bold">
                Deriváty, obchodovanie na úver, resp. na páku
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in question8Answers"
              :key="index">
              <td>
                {{ item.label }}
              </td>
              <td class="text-center">
                <v-checkbox
                  class="body-2 ms-auto d-inline-block"
                  v-model="form.question8a"
                  :error="$v.form.question8a.$anyError"
                  :key="'dlhopisy' + item.value"
                  :value="item.value"
                ></v-checkbox>
              </td>
              <td class="text-center">
                <v-checkbox
                  class="body-2 ms-auto d-inline-block"
                  v-model="form.question8b"
                  :error="$v.form.question8b.$anyError"
                  :key="'fondy' + item.value"
                  :value="item.value"
                ></v-checkbox>
              </td>
              <td class="text-center">
                <v-checkbox
                  class="body-2 ms-auto d-inline-block"
                  v-model="form.question8c"
                  :error="$v.form.question8c.$anyError"
                  :key="'akcie' + item.value"
                  :value="item.value"
                ></v-checkbox>
              </td>
              <td class="text-center">
                <v-checkbox
                  class="body-2 ms-auto d-inline-block"
                  v-model="form.question8d"
                  :error="$v.form.question8d.$anyError"
                  :key="'margin' + item.value"
                  :value="item.value"
                ></v-checkbox>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row>

      <question-radio :question="question9" v-model="form.question9" :error="$v.form.question9.$anyError" />

      <question-radio :question="resultFormat" v-model="form.resultFormat" :error="$v.form.resultFormat.$anyError" />

      <question-radio :question="aml" v-model="form.aml" :error="$v.form.aml.$anyError" />

      <question-radio :question="amlBonus" v-model="form.amlBonus" :error="$v.form.amlBonus.$anyError" />

      <question-radio :question="amlDeclaration" v-model="form.amlDeclaration" :error="$v.form.amlDeclaration.$anyError" />

    </v-row>

    <v-row>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="pb-0">
        <v-checkbox
          class="pb-0"
          :error="$v.form.gdprAgreement.$anyError"
          v-model="form.gdprAgreement"
          label="Súhlasím s podmienkami spracovania osobných údajov"></v-checkbox>
        <v-checkbox
          class="mt-0 pt-0"
          v-model="form.marketingAgreement"
          label="Súhlasím s podmienkami spracovania osobných údajov na marketingové účely"></v-checkbox>
      </v-col>

    </v-row>

    <v-row class="text-center font-weight-bold">

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <p>
          Po vyplnení tohto dotazníka Vám záznam o výkone finančného sprostredkovania bude odoslaný na e-mailovú adresu uvedenú pri registrácií.
        </p>
      </v-col>

    </v-row>

    <v-row class="text-right mb-4">

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <v-alert
          v-if="showNotification"
          :elevation="12"
          :prominent="true"
          :dismissible="true"
          @input="showNotification = false"
          :transition="'fade-transition'"
          :icon="notificationIcon"
          :type="notificationColor">
          {{ notificationText }}
        </v-alert>
        <v-btn
          tile
          :disabled="notification === 3"
          :loading="loading"
          @click="submitForm()"
          color="success">
          <span v-if="notification < 3">Odoslať</span>
          <span v-else>
            <v-icon left>
              mdi-check
            </v-icon>
            Odoslané
          </span>
        </v-btn>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import QuestionRadio from './../components/QuestionRadio'
import axios from 'axios'


export default {
  name: 'Questionnaire',

  mixins: [validationMixin],

  directives: {
    mask
  },

  components: {
    QuestionRadio
  },

  data() {
    return {
      notification: 0,
      notificationText: '',
      showNotification: false,
      loading: false,
      form: {
        fullName: '',
        email: '',
        phone: '',
        birthdate: '',
        birthNumber: '',
        personalCard: '',
        iban: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        question1: '',
        question1Other: '',
        question2: '',
        question3: '',
        question4: '',
        question5: '',
        question6: '',
        question7: '',
        question8a: [],
        question8b: [],
        question8c: [],
        question8d: [],
        question9: '',
        resultFormat: '',
        aml: '',
        amlBonus: '',
        amlDeclaration: '',
        gdprAgreement: false,
        marketingAgreement: false
      },
      countries: [],
      birthdayMenu: false,
      question1: {
        number: 1,
        question: 'Odkiaľ pochádzajú Vaše finančné prostriedky, ktoré plánujete investovať?',
        answers: [
          {label: 'z príjmu z podnikateľskej činnosti, z príjmu plynúceho z vlastníctva akcií spoločností, z príjmu plynúceho z vlastníctva nehnuteľností', value: 'a'},
          {label: 'z príjmu zo zamestnania (mesačná mzda)', value: 'b'},
          {label: 'z dôchodku, z výživného', value: 'c'},
          {label: 'z dedičstva, darov, výhier v lotérii ', value: 'd'},
          {label: 'iné', value: 'e'},
        ]
      },
      question1Answers: '',
      question2: {
        number: 2,
        question: 'Označte prosím stupeň rizika straty Vašich investícií, ktorý ste ochotný/á akceptovať?',
        answers: [
          {label: 'Akceptujem, že pri investovaní existuje vysoké riziko straty časti mojich investovaných finančných prostriedkov alebo vysoké riziko straty všetkých mojich investovaných finančných prostriedkov a som ochotný/á toto riziko zniesť ', value: 'a'},
          {label: 'Akceptujem, že  pri investovaní existuje stredné riziko straty časti mojich investovaných finančných prostriedkov alebo všetkých mojich investovaných finančných prostriedkov a som ochotný/á toto riziko zniesť ', value: 'b'},
          {label: 'Akceptujem len menšie riziko straty investovaných finančných prostriedkov a som ochotný/á zniesť iba toto menšie riziko', value: 'c'},
          {label: 'Neakceptujem žiadne riziko straty investovaných finančných prostriedkov a ani nie som ochotný/á zniesť žiadne riziko straty čo i len časti mojich investovaných finančných prostriedkov', value: 'd'}
        ]
      },
      question3: {
        number: 3,
        question: 'Uveďte prosím, ktoré z nižšie uvedených tvrdení najlepšie vystihuje účel Vašej investície a postoj k Vašej investícií?',
        answers: [
          {label: 'Mám iba nízku finančnú rezervu, ktorú môžem kedykoľvek rýchlo potrebovať ', value: 'a'},
          {label: 'Mám voľné finančné prostriedky, ktoré chcem zhodnotiť a teraz ich nebudem potrebovať', value: 'b'},
          {label: 'Mám nadbytočné finančné prostriedky, ktoré by som rád investoval za účelom čo najvyššieho možného výnosu ', value: 'c'}
        ]
      },
      question4: {
        number: 4,
        question: 'Aké sú Vaše investičné ciele a Váš postoj k riziku?',
        answers: [
          {label: 'preferujem aktívne investovanie, vyšší výnos so stredným stupňom rizika a uvedomujem si, že za určitých okolností môžem stratiť vyššiu časť mojej investície alebo celkovú výšku mojej investície', value: 'a'},
          {label: 'preferujem nadpriemerný, prípadne vysoký výnos s vysokým stupňom rizika a uvedomujem si, že za určitých okolností môžem stratiť značnú časť mojej investície alebo celkovú výšku mojej investície  ', value: 'b'},
          {label: 'preferujem nízke zhodnotenie mojej investície pri veľmi nízkej miere ', value: 'c'}
        ]
      },
      question5: {
        number: 5,
        question: 'Uveďte čo by pre vás znamenala prípadná celková strata Vašich investovaných finančných prostriedkov',
        answers: [
          {label: 'existenčné problémy ', value: 'a'},
          {label: 'výraznú zmenu môjho životného štandardu  ', value: 'b'},
          {label: 'nevýraznú zmenu môjho životného štandardu ', value: 'c'},
          {label: 'žiadnu zmenu môjho životného štandardu, t.j. stratu by som nepocítil/a', value: 'd'}
        ]
      },
      question6: {
        number: 6,
        question: 'Čím je charakteristická akcia?',
        answers: [
          {label: 'Ide o záväzok splatiť peňažné prostriedky (dlh) a vyplácať úroky z týchto peňažných prostriedkov ', value: 'a'},
          {label: 'Ide o podiel v akciovej spoločnosti, ktorý predstavuje konkrétny podiel investora na majetku akciovej spoločnosti a na jej zisku', value: 'b'},
          {label: 'Ide o finančný nástroj - fond, ktorý je obchodovaný na burze a ktorý kopíruje vývoj podkladového aktíva (napr. index, komodita)', value: 'c'},
        ]
      },
      question7: {
        number: 7,
        question: 'Dividenda je',
        answers: [
          {label: 'výnos vyplatený akcionárovi zo zisku akciovej spoločnosti, v prípade, že tak rozhodne valné zhromaždenie ', value: 'a'},
          {label: 'podiel akcionára na likvidačnom zostatku ', value: 'b'},
          {label: 'právo akcionára na zvolanie mimoriadneho valného zhromaždenia ', value: 'c'},
        ]
      },
      question8Answers: [
        { label: 'Neinvestoval/a som nikdy', value: 'a' },
        { label: 'Investoval/a som v minulosti', value: 'b' },
        { label: 'Investujem na pravidelnej (mesačnej alebo štvrťročnej) báze', value: 'c' },
        { label: 'Od mojej prvej investície uplynul viac ako 1 rok', value: 'd' },
        { label: 'Investoval/a som celkom viac ako 3.000,- EUR', value: 'e' }
      ],
      question9: {
        number: 9,
        question: 'Uveďte prosím či máte pracovnú skúsenosť (za posledných 10 rokov) a/alebo ste získali vzdelanie v odbore, ktoré súvisí s kapitálovým trhom, finančnými službami či investovaním do finančných nástrojov? ',
        answers: [
          {label: 'NIE', value: 'a'},
          {label: 'ÁNO, mám pracovnú skúsenosť (pracujem alebo pôsobím vo finančnom sektore alebo zastávam/ skôr som zastával pozíciu priamo spojenú s obchodovaním s investičnými nástrojmi (napr. makléra, portfólio manažéra, investičného poradcu a pod.)) ', value: 'b'},
          {label: 'ÁNO, mám vzdelanie v odbore (mám vysokoškolské vzdelanie so zameraním okrem iného aj na finančné trhy a finančné nástroje alebo som zložil odbornú skúšku/prešiel som odborným kurzom v súvislosti s finančnými nástrojmi) ', value: 'c'},
        ]
      },
      resultFormat: {
        question: "Vyhodnotenie odpovedí a informácie podľa zákona č. 186/2009 Z. z. o finančnom sprostredkovaní a finančnom poradenstve mám záujem zaslať: ",
        answers: [
          {label: 'v papierovej forme na vyššie uvedenú adresu ', value: 'a'},
          {label: 'v elektronickej forme (e-mail a webstránka spoločnosti) ', value: 'b'}
        ]
      },
      aml: {
        question: "V súlade so zákonom č. 297/2008 Z. z. o ochrane pred legalizáciou príjmov z trestnej činnosti a o ochrane pred financovaním terorizmu a o zmene a doplnení niektorých zákonov v znení neskorších predpisov (ďalej ako „zákon o AML“) záväzne vyhlasujem, že finančné prostriedky, ktoré plánujem použiť na investíciu do niektorého z produktov finančných inštitúcií, s ktorými má spoločnosť AppleTrade s.r.o. uzatvorenú písomnú zmluvu, ",
        answers: [
          {label: 'sú mojím vlastníctvom ', value: 'a'},
          {label: 'sú vo vlastníctve inej osoby ', value: 'b'}
        ]
      },
      amlBonus: {
        question: 'a zároveň prehlasujem, že obchod na ktorý sa majú predmetné finančné prostriedky použiť ',
        answers: [
          {label: 'vykonávam na vlastný účet a vo vlastnom mene', value: 'a'},
          {label: 'vykonávam na účet a v mene inej osoby ', value: 'b'},
        ]
      },
      amlDeclaration: {
        question: 'Týmto tiež prehlasujem, že nie som politicky exponovanou osobou alebo sankcionovanou osobou v zmysle zákona o AML ',
        answers: [
          {label: 'ÁNO', value: 'a'},
          {label: 'NIE', value: 'b'},
        ]
      }
    }
  },

  validations: {
    form: {
      fullName: { required, minLength: minLength(6) },
      email: { required, email },
      phone: { required },
      birthdate: { required },
      birthNumber: { required, maxLength: maxLength(20) },
      personalCard: { required, minLength: minLength(6), maxLength: maxLength(20) },
      iban: { required, minLength: minLength(8), maxLength: maxLength(30) },
      street: { required },
      zip: { required },
      city: { required },
      country: { required },
      question1: { required },
      question1Other: {
        required: requiredIf(function() {
          return this.form.question1 === 'e'
        })
      },
      question2: { required },
      question3: { required },
      question4: { required },
      question5: { required },
      question6: { required },
      question7: { required },
      question8a: { required },
      question8b: { required },
      question8c: { required },
      question8d: { required },
      question9: { required },
      resultFormat: { required },
      aml: { required },
      amlBonus: { required },
      amlDeclaration: { required },
      gdprAgreement: {
        sameAs: sameAs(function() {
          return true
        })
      },
    },
  },

  watch: {
    question1Answers(val) {
      this.form.question1 = val
    }
  },

  computed: {
    birthdayFormatted () {
      return this.formatDate(this.form.birthdate)
    },
    notificationColor() {
      if ( this.notification === 1 || this.notification === 2 ) {
        return 'error'
      } else {
        return 'success'
      }
    },
    notificationIcon() {
      if ( this.notification === 1 || this.notification === 2 ) {
        return 'mdi-alert-circle-outline'
      } else {
        return 'mdi-check'
      }
    }
  },

  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    submitForm() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.loading = false;
        this.notificationText = 'Vyplňte prosím všetky povinné polia'
        this.notification = 1;
        this.showNotification = true;
      } else {
        axios.post('send-form', {
          questionnaire: this.form
        }).then(response => {
          if ( response.data.result === true ) {
            this.notification = 3;
            this.notificationText = response.data.message
          } else {
            this.notification = 2
            this.notificationText = response.data.message
          }
        }).finally(() => {
          this.loading = false;
          this.showNotification = true;
        });
      }
    }
  },

  created() {
    const html = document.documentElement
    html.setAttribute('lang', 'sk')
    this.countries = require('./../assets/countries.json')
  }
}
</script>
