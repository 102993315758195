<template>
    <v-app>
        <v-app-bar app>
            Test primeranosti
        </v-app-bar>

        <!-- Sizes your content based upon application components -->
        <v-main>

            <!-- Provides the application the proper gutter -->
            <v-container fluid>

                <questionnaire></questionnaire>
            </v-container>
        </v-main>

        <v-footer app>
            <!-- -->
        </v-footer>
    </v-app>
</template>

<script>
import Questionnaire from './components/Questionnaire';

export default {
  name: 'App',

  components: {
    Questionnaire,
  },

  data: () => ({
    //
  }),
};
</script>
